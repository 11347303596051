<template>
<b-card :title="$t('notification')">
    <b-alert show variant="primary">Primary Alert</b-alert>
    <b-alert show variant="secondary">Secondary Alert</b-alert>
    <b-alert show variant="success">Success Alert</b-alert>
    <b-alert show variant="danger">Danger Alert</b-alert>
    <b-alert show variant="warning">Warning Alert</b-alert>
    <b-alert show variant="info">Info Alert</b-alert>
    <b-alert show variant="light">Light Alert</b-alert>
    <b-alert show variant="dark">Dark Alert</b-alert>
</b-card>
</template>
<script>
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('notification') }
    }
}
</script>
